/* eslint-disable react/jsx-filename-extension */
// This polyfill is somehow needed for the old project settings Bill Rates component to work.
// We should be able to remove this if nothing else relies on MutationObserver
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
// eslint-disable-next-line unused-imports/no-unused-imports-ts, no-unused-vars
import { getWidthMultiplier } from '@smartsheet/lib.locale-aware';
import { initI18Next } from '@smartsheet/lib.translations-mapper';
// eslint-disable-next-line unused-imports/no-unused-imports-ts, no-unused-vars
import { BiEventLogger } from '@smartsheet/rm-event-logger';

import App from './components/App';
import { resources } from '../../l10n/resources';
import '../client/stylesheets/globals.scss';

import { CheckCSRF } from './components/CheckCSRF';
import { getCsrfToken } from '@smartsheet/api.utils';

const i18nInstance = initI18Next(resources, i18n);

window.loadAccountSwitcher = (element = 'root') => {
  const rootElement = document.getElementById(element);
  const csrfToken = getCsrfToken();
  if (rootElement) {
    ReactDOM.render(
      <I18nextProvider i18n={i18nInstance}>
        {csrfToken && <CheckCSRF />}
        <App />
      </I18nextProvider>,
      rootElement
    );
  }
};

window.unloadAccountSwitcher = (element) => {
  const rootElement = document.getElementById(element);
  ReactDOM.unmountComponentAtNode(rootElement);
};
