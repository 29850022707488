function menuItem(ind, elementId, buttonLabel, attachTo, dropDown, callback) {
  var _i = this;

  // params
  _i.ind = ind;
  _i.buttonLabel = buttonLabel;
  _i.attachTo = xGetElementById(attachTo);
  _i.callback = callback;

  // default name width
  _i.lastKnownNameWidth = null;
  _i.trialWidth = null;

  // locals
  _i.container = null;
  _i.labelCont = null;

  _i.destroy = function () {
    _i.attachTo.removeChild(_i.container);
  };

  _i.isSelected = function () {
    return hasClass(_i.container, 'selected');
  };

  _i.select = function () {
    addClass(_i.container, 'selected');
  };

  _i.unselect = function () {
    removeClass(_i.container, 'selected');
  };

  _i.setLabel = function (value) {
    _i.labelCont.innerText = value;
  };

  _i.containerClick = function (evt) {
    cancelEvent(evt);

    if (_i.callback) {
      _i.callback(_i.ind, _i.container);
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.style.width = 'auto';
    _i.container.style.height = '100%';
    _i.container.className = 'fnt-r-14 menuButtonItem blockFloat';
    if (gData.accountSettings.enterprise_header_shadow_enabled === false) {
      _i.container.style.textShadow = 'none';
    }

    if (elementId) {
      _i.container.id = elementId;
    }

    _i.labelCont = document.createElement('BUTTON');
    _i.labelCont.className = 'menuButtonItemLabel floatLeft';
    _i.labelCont.innerText = _i.buttonLabel;

    if (gData.accountSettings.enterprise_header_color) {
      _i.labelCont.style.color = gData.accountSettings.enterprise_header_color;
    }

    _i.container.appendChild(_i.labelCont);

    if (dropDown) {
      _i.labelIcon = document.createElement('img');
      _i.labelIcon.className = 'plansHeaderArrowDown';
      _i.labelIcon.setAttribute(
        'src',
        STATIC_IMAGE_URL + '/plans_drop_down_arrow.svg'
      );
      _i.labelIcon.setAttribute('role', 'presentation');
      _i.labelIcon.style.position = 'absolute';
      _i.labelCont.style.marginRight = '20px';
      _i.labelIcon.style.right = '10px';
      _i.labelIcon.style.top = '23px';
      _i.labelCont.appendChild(_i.labelIcon);
    }

    _i.attachTo.appendChild(_i.container);

    xAddEventListener(_i.container, platform.click, _i.containerClick, false);
  };

  _i.constructor();
}

function inIframeButNotGuide() {
  var isGuide =
    (window.frameElement && window.frameElement.id === 'app') ||
    window.isIframeView;
  try {
    var isIframe = window.self !== window.top;
    return isIframe && !isGuide;
  } catch (e) {
    return true && !isGuide;
  }
}

/*
    CLASS: pageHeader( attachTo ) : string or DOM element to attach container
      .addControl( control ) : attaches an instance of a control to its container, internally assigns a control index, and returns the control
      .removeControl( control ) : removes and destroys a control that was previously attached with .addControl

*/
function pageHeader(attachTo, selectedindex, username, companyname) {
  var _i = this;

  _i.attachTo = xGetElementById(attachTo);

  if (!window.isIframeView) {
    _i.attachTo.style.display = 'block';
  }

  _i.index = selectedindex;

  _i.username = username ? username : I18n.t('lbl_profile');

  _i.companyname = companyname ? companyname : I18n.t('lbl_overview');

  _i.spinCount = 0;

  _i.menuItems = [];

  _i.getMenuMask = function () {
    var mask = 0x0, // default to none
      F_ORG = 0x1,
      F_ME = 0x2,
      F_PROJECTS = 0x4,
      F_SCHEDULE = 0x8,
      F_ANALYTICS = 0x10,
      F_ADMIN_DASH = 0x20;

    var user = window.whoami;
    if (user) {
      var userType = user.user_type_id;
      switch (userType) {
        case 5: // Contractor
          mask = F_ME;
          break;
        case 4: // Portfolio viewer
          mask = F_ORG | F_ME | F_PROJECTS | F_SCHEDULE;
          if (gData.accountSettings.moduleEnabled('app_project_members')) {
            mask |= F_ANALYTICS;
          }
          break;
        default:
          mask = F_ORG | F_ME | F_PROJECTS | F_SCHEDULE | F_ANALYTICS;
      }

      if (user.show_ad === true) {
        mask |= F_ADMIN_DASH;
      }
    }
    return mask;
  };

  var spinnerMargin = 6;
  var RIGHT_ALIGN_FROM_INDEX = 5;
  var ORG_INDEX = 0;
  var ME_INDEX = 1;
  var PROJECTS_INDEX = 2;

  _i.destroy = function () {
    _i.container && _i.attachTo.removeChild(_i.container);
  };

  _i.onClickMainMenuItem = function (items) {
    if (items && items.length > 0) {
      var id = items[0].id;
      var action = _i.menuItems[id].action;
      action && action();
    }
  };

  _i.showMainMenu = function (i, button) {
    var mask = _i.getMenuMask();
    if (!_i.mainMenu) {
      var data = [],
        item;
      for (var i = 0; i < RIGHT_ALIGN_FROM_INDEX; i++) {
        item = _i.menuItems[i];
        if (item.module_enabled_flag & mask) {
          data.push({
            id: i,
            type: 'item',
            label: item.text,
          });
        }
      }

      _i.mainMenu = new selectionPopup(
        200,
        31 * data.length,
        attachTo,
        data,
        compSpritesButtonsIcons.wideDialogSpecs,
        _i.onClickMainMenuItem,
        false,
        null,
        'nw',
        null,
        true,
        false
      );
    }

    _i.mainMenu.show(button, 0, 5);
  };

  _i.showSupportMenu = function (i, button) {
    var data = [];

    data.push({
      id: 'learnmore',
      type: 'item',
      label: I18n.t('lbl_help_topics'),
    });

    data.push({
      id: 'feedback',
      type: 'item',
      label: I18n.t('lbl_submit_feedback'),
    });

    data.push({
      id: 'contact',
      type: 'item',
      label: I18n.t('lbl_contact_support'),
    });

    _i.supportMenu =
      _i.supportMenu ||
      new selectionPopup(
        165,
        31 * data.length,
        attachTo,
        data,
        compSpritesButtonsIcons.wideDialogSpecs,
        _i.onSupportMenuClick,
        false,
        null,
        'ne',
        null,
        true,
        false
      );

    _i.supportMenu.show(button, 0, 5);
  };

  _i.showSettingsMenu = function (i, button) {
    var data = [];

    data.push({
      id: 'prefs',
      type: 'item',
      label: I18n.t('lbl_my_preferences'),
    });

    if (
      gData.accountSettings &&
      gData.getMe() &&
      gData.accountSettings.canUserEditModel(gData.getMe(), 'AccountSetting')
    ) {
      data.push({
        id: 'accountSettings',
        type: 'item',
        label: I18n.t('lbl_account_settings'),
      });
    }

    if (!(window.frameElement && window.frameElement.id === 'app')) {
      data.push({
        id: 'signOut',
        type: 'item',
        label: I18n.t('lbl_signout'),
      });
    }

    _i.settingsMenu =
      _i.settingsMenu ||
      new selectionPopup(
        165,
        31 * data.length,
        attachTo,
        data,
        compSpritesButtonsIcons.wideDialogSpecs,
        _i.onSettingsMenuClick,
        false,
        null,
        'ne',
        null,
        true,
        false
      );

    _i.settingsMenu.show(button, 0, 5);
  };

  _i.showAddMenu = function (i, button) {
    var data = [
      {
        id: 'projectRequest',
        type: 'item',
        label: I18n.t('lbl_project_request'),
      },
    ];

    var adminTypeId = 1;
    if (window.whoami.user_type_id === adminTypeId) {
      data.push({
        id: 'projectApproval',
        type: 'item',
        label: I18n.t('lbl_project_approval'),
      });
    }

    _i.addMenu =
      _i.addMenu ||
      new selectionPopup(
        165,
        31 * data.length,
        attachTo,
        data,
        compSpritesButtonsIcons.wideDialogSpecs,
        _i.onAddMenuClick,
        false,
        null,
        'ne',
        null,
        true,
        false
      );

    _i.addMenu.show(button, 0, 5);
  };

  function initializeMenuItems() {
    _i.menuItems = [];
    _i.menuItems.push({
      text: window.accountSettings.company_name,
      action: function () {
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'ButtonClick',
            elementLocation: 'RMNavBar',
            elementName: 'People',
          },
        });
        window.location = '/people';
      },
      module_enabled_flag: 0x01,
    });

    _i.menuItems.push({
      text: _i.username,
      action: function () {
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'ButtonClick',
            elementLocation: 'RMNavBar',
            elementName: 'Person',
          },
        });

        window.location = '/me';
      },
      module_enabled_flag: 0x02,
    });

    _i.menuItems.push({
      text: I18n.t('lbl_projects'),
      action: function () {
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'ButtonClick',
            elementLocation: 'RMNavBar',
            elementName: 'Projects',
          },
        });

        window.location = '/projects';
      },
      module_enabled_flag: 0x04,
    });

    _i.menuItems.push({
      text: I18n.t('lbl_schedule'),
      action: function () {
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'ButtonClick',
            elementLocation: 'RMNavBar',
            elementName: 'Schedule',
          },
        });
        window.location = '/plan';
      },
      module_enabled_flag: 0x08,
    });

    _i.menuItems.push({
      text: I18n.t('lbl_analytics'),
      action: function () {
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'ButtonClick',
            elementLocation: 'RMNavBar',
            elementName: 'Analytics',
          },
        });
        window.location = '/analytics';
      },
      module_enabled_flag: 0x10,
    });

    if (window.whoami.show_ad === true) {
      _i.menuItems.push({
        text: I18n.t('lbl_admin_dashboard'),
        action: function () {
          window.open(AD_BASE_URL, '_blank');
        },
        module_enabled_flag: 0x20,
      });
    }

    // if ((window.whoami.user_type_id === 1 || window.whoami.user_type_id === 8) && isPamEnabled())
    // {
    //   _i.menuItems.push({
    //     text: "Provide Beta Feedback",
    //     action: function() {
    //       window.open('9643cca92edf43739585e2f5bbd25691', '_blank');
    //     },
    //     module_enabled_flag: 0x01
    //   });
    // }

    var contractor = 5;

    var hasNewMenuPermissions =
      _.intersection([contractor], [window.whoami.user_type_id]).length === 0;

    if (
      gData.accountSettings.moduleEnabled('workflows') &&
      hasNewMenuPermissions
    ) {
      _i.menuItems.push({
        text: I18n.t('lbl_new'),
        dropDown: true,
        action: _i.showAddMenu,
        elementId: 'addMenuButton',
      });
    }

    _i.menuItems.push({
      text: I18n.t('lbl_help'),
      dropDown: true,
      action: _i.showSupportMenu,
      elementId: 'feedbackandsupport',
      action2: function () {},
    });

    if (!inIframeButNotGuide()) {
      _i.menuItems.push({
        elementId: 'settingsmenu',
        text: I18n.t('lbl_settings'),
        dropDown: true,
        action: _i.showSettingsMenu,
      });
    }
  }

  _i.container = null;
  _i.controls = [];
  _i.buttons = [];

  _i.gotoBilling = function () {
    if (
      gData.accountSettings.canUserEditModel(gData.getMe(), 'AccountSetting')
    ) {
      setCookie(AppCookie.SettingsFilter, '13');
      window.location = '/settings';
    }
  };

  _i.updateOrgName = function (name) {
    _i.buttons[ORG_INDEX].setLabel(name);
  };

  _i.updateMyName = function (name) {
    _i.buttons[ME_INDEX].setLabel(name);
  };

  _i.updateSelectedIndex = function (selectedIndex) {
    _i.setSelection(selectedIndex);
  };

  _i.getMeIndex = function () {
    return ME_INDEX;
  };

  _i.getProjectsIndex = function () {
    return PROJECTS_INDEX;
  };

  _i.getOrgIndex = function () {
    return ORG_INDEX;
  };

  _i.onSupportMenuClick = function (items) {
    if (items && items.length > 0) {
      var id = items[0].id;
      if (id === 'contact') {
        if (gData.accountSettings.enterprise_support_url) {
          window.open(gData.accountSettings.enterprise_support_url, '_blank');
          report_page_view('/redirect_to_enterprise_support');
        } else {
          window.open(window.SMARTSHEET_SUPPORT_URL, '_blank');
          report_page_view('/feedback_and_support');
        }
      } else if (id === 'feedback') {
        window.open(
          "javascript:window.location='https://app.smartsheet.com/b/form/739aa75f30ca43a8a22eb53e4da7d409?What%20is%20your%20feedback%20about?=Resource%20Management'",
          '_blank'
        );
        report_page_view('/submit_feedback');
      } else {
        report_page_view('/learn_more');
        window.open(
          'https://help.smartsheet.com/resource-management',
          '_blank'
        );
      }
    }
  };

  _i.onSettingsMenuClick = function (items) {
    if (items.length) {
      var id = items[0].id;
      if (id == 'signOut') {
        report_page_view('/signout');
        function cb() {
          if (gData.accountSettings.enterprise_signout_url) {
            window.location = gData.accountSettings.enterprise_signout_url;
          } else {
            window.location = SIGN_IN_URL;
          }
        }
        window.clientEventLogger?.push({
          eventTimestamp: Date.now(),
          eventData: {
            featureArea: 'ResourceManagement',
            eventName: 'MenuClick',
            elementLocation: 'RMNavBar',
            elementName: 'Settings',
            properties: { value: 'SignOut' },
          },
        });
        signOut(cb);
      } else {
        if (items[0].id == 'prefs') {
          window.clientEventLogger?.push({
            eventTimestamp: Date.now(),
            eventData: {
              featureArea: 'ResourceManagement',
              eventName: 'MenuClick',
              elementLocation: 'RMNavBar',
              elementName: 'Settings',
              properties: { value: 'MyPreferences' },
            },
          });
          setCookie(AppCookie.SettingsFilter, 1);
        } else if (items[0].id == 'accountSettings') {
          window.clientEventLogger?.push({
            eventTimestamp: Date.now(),
            eventData: {
              featureArea: 'ResourceManagement',
              eventName: 'MenuClick',
              elementLocation: 'RMNavBar',
              elementName: 'Settings',
              properties: { value: 'AccountSettings' },
            },
          });
          setCookie(AppCookie.SettingsFilter, 2);
        }

        window.location = '/settings';
      }
    }
  };

  _i.onAddMenuClick = function (items) {
    if (!items.length) return;
    var id = items[0].id;
    if (id === 'projectRequest') {
      window.open(window.WORKFLOWS_FORM_URL, '_blank');
    }
    if (id === 'projectApproval') {
      window.open(window.WORKFLOWS_DASHBOARD_URL, '_blank');
    }
  };

  _i.setSelection = function (index) {
    var i = 0;
    index = Math.min(index, _i.buttons.length - 1);
    _i.buttons.forEach(function (b) {
      if (i == index) {
        b.select();
      } else {
        b.unselect();
      }
      i++;
    });
  };

  _i.setSpinner = function (spin) {
    if (spin) {
      _i.spinCount++;
      _i.img.style.display = 'block';
    } else {
      _i.spinCount--;
      if (_i.spinCount < 0) _i.spinCount = 0;
      if (_i.spinCount == 0) {
        _i.img.style.display = 'none';
      }
    }
    _i.windowResize();
  };

  // Returns zero if the spinner isn't currently being displayed, otherwise
  // returns the spinner width including margins.
  _i.getSpinnerWidth = function () {
    if (_i.img.style.display == 'block') {
      // Including the margin from the style in components.css
      return xWidth(_i.img) + spinnerMargin * 2;
    } else return 0;
  };

  _i.windowResize = function () {
    var ddl_margin_added = 110;
    var list_margin_added = 20;
    var default_logo_width = 100;
    var default_name_width = 140;
    var menuItemsMinSize = 439; //anything below this size can't be rendered on the extended main menu

    var width = $(window).width();
    if (width < 900) {
      if (
        _i.containerTrial &&
        _i.containerTrial.style &&
        _i.containerTrial.style.display == 'block'
      ) {
        _i.containerTrial.style.display = 'none';
      }
    } else if (
      _i.containerTrial &&
      _i.containerTrial.style &&
      _i.containerTrial.style.display == 'none'
    ) {
      _i.containerTrial.style.display = 'block';
    }

    var nameW = _i.lastKnownNameWidth || _i.buttons[0].container.clientWidth;
    if (nameW > 0) _i.lastKnownNameWidth = nameW;
    else nameW = _i.lastKnownNameWidth;

    var trialW =
      _i.trialWidth || _i.buttons[RIGHT_ALIGN_FROM_INDEX].container.clientWidth;
    if (trialW > 0) _i.trialWidth = trialW;
    else trialW = _i.trialWidth;

    var rightW =
      _i.rightWidth ||
      _i.buttons[RIGHT_ALIGN_FROM_INDEX].attachTo.clientWidth + 30;
    if (!_i.rightWidth) _i.rightWidth = rightW;

    var logoW = xWidth(_i.logoImage) || default_logo_width;
    var size = width - (logoW + rightW);

    if (
      _i.containerTrial &&
      _i.containerTrial.style &&
      _i.containerTrial.style.display == 'none'
    )
      size += trialW;

    _i.leftItemsWidth = _i.leftItemsWidth || xWidth(_i.leftItems);
    var list_space_required = Math.max(
      _i.leftItemsWidth + nameW / 2,
      menuItemsMinSize
    );
    var ddl_space_required = nameW + ddl_margin_added;

    if (size > list_space_required) {
      // Display List
      if (_i.containerTrial && _i.containerTrial.style)
        _i.containerTrial.style.display = 'block';
      if (
        _i.leftItems &&
        _i.leftItems.style &&
        _i.leftItems.style.display != 'block'
      )
        _i.leftItems.style.display = 'block';
      if (
        _i.leftItemsDropdown &&
        _i.leftItemsDropdown.style &&
        _i.leftItemsDropdown.style.display != 'none'
      )
        _i.leftItemsDropdown.style.display = 'none';
    } else {
      if (_i.containerTrial && _i.containerTrial.style)
        _i.containerTrial.style.display = 'block';
      if (_i.leftItems && _i.leftItems.style)
        _i.leftItems.style.display = 'none';
      if (_i.leftItemsDropdown && _i.leftItemsDropdown.style)
        _i.leftItemsDropdown.style.display = 'block';
    }
  };

  _i.constructor = function () {
    var mask = _i.getMenuMask();

    initializeMenuItems();

    _i.container = document.createElement('DIV');
    _i.container.className = 'pageHeader blockFloatNot';

    // if (LOCAL_BUILD)
    // {
    //   _i.container.className += " pageHeaderLocalEnv";
    // }
    // else if (!PRODUCTION_BUILD)
    // {
    //   _i.container.className += " pageHeaderDevEnv";
    // }

    if (gData.accountSettings.enterprise_header_bgcolor) {
      _i.container.style.backgroundColor =
        gData.accountSettings.enterprise_header_bgcolor;
    }

    _i.attachTo.appendChild(_i.container);

    _i.innercontainer = document.createElement('DIV');
    _i.innercontainer.className = 'blockFloat pageHeaderInnerContainer';
    _i.container.appendChild(_i.innercontainer);

    if (gData.accountSettings.enterprise_header_logo_url) {
      _i.logoIcon = document.createElement('div');
      _i.logoIcon.className = 'plansHeaderLogoCustom';
      _i.logoIcon.style.width = 'auto';
      _i.logoIcon.style.display = 'inline-block';
      _i.logoImage = document.createElement('IMG');
      _i.logoImage.className = 'headerLogoCustomImage';
      _i.logoImage.src = gData.accountSettings.enterprise_header_logo_url;
      _i.logoImage.alt = '';
      _i.logoImage.setAttribute('role', 'presentation');
      _i.logoIcon.appendChild(_i.logoImage);
      if (gData.accountSettings.enterprise_portal_url) {
        xAddEventListener(
          _i.logoIcon,
          platform.click,
          function () {
            window.location = gData.accountSettings.enterprise_portal_url;
          },
          false
        );
        _i.logoIcon.style.cursor = 'pointer';
      }
    } else {
      _i.logoIcon = document.createElement('img');
      _i.logoIcon.className = 'plansHeaderLogo';
      _i.logoIcon.setAttribute(
        'src',
        STATIC_IMAGE_URL + '/resource-management-icon-light.svg'
      );
      _i.logoIcon.alt = '';
      _i.logoIcon.setAttribute('role', 'presentation');
    }

    _i.innercontainer.appendChild(_i.logoIcon);
    _i.accountSwitcher = document.createElement('div');
    _i.accountSwitcher.id = 'accountSwitcher';
    _i.accountSwitcher.className = 'accountSwitcher';
    if (typeof window.loadAccountSwitcher === 'function') {
      _i.innercontainer.appendChild(_i.accountSwitcher);
      window.loadAccountSwitcher('accountSwitcher');
    }
    _i.leftItems = document.createElement('NAV');
    _i.leftItems.setAttribute('aria-label', 'primary');
    _i.leftItems.className = 'blockFloat menuButtonsLeft unselectable';

    _i.leftItemsDropdown = document.createElement('DIV');
    _i.leftItemsDropdown.className = 'blockFloat menuButtonsLeft unselectable';

    _i.rightItems = document.createElement('NAV');
    _i.rightItems.setAttribute('aria-label', 'secondary');
    _i.rightItems.className = 'blockFloat menuButtonsRight unselectable';
    _i.rightItems.style.float = 'right';

    _i.img = document.createElement('IMG');
    _i.img.src = STATIC_IMAGE_URL + '/progress-indicator_24.gif';
    _i.img.className = 'blockFloat spinnerImagePageHeader';
    _i.img.alt = '';
    _i.img.setAttribute('role', 'presentation');
    _i.img.marginLeft = _i.img.marginRight = spinnerMargin + 'px';
    if (!gDataLoading) _i.img.style.display = 'none';
    else _i.spinCount++;

    _i.rightItems.appendChild(_i.img);

    var i, item;
    for (i = 0; i < RIGHT_ALIGN_FROM_INDEX; i++) {
      item = _i.menuItems[i];
      if (!(item.module_enabled_flag & mask)) item.text = '';
      _i.buttons.push(
        new menuItem(
          i,
          item.elementId,
          item.text,
          _i.leftItems,
          item.dropDown,
          item.action
        )
      );
    }

    // Add alternate popup menu
    new menuItem(
      0,
      null,
      _i.companyname,
      _i.leftItemsDropdown,
      true,
      _i.showMainMenu
    );

    _i.innercontainer.appendChild(_i.leftItemsDropdown);
    _i.innercontainer.appendChild(_i.leftItems);
    _i.innercontainer.appendChild(_i.rightItems);

    var trialend =
      window.accountSettings && window.accountSettings.trial_ends_at;

    if (
      trialend &&
      !inIframeButNotGuide() &&
      window.accountSettings.account_status != 'active'
    ) {
      var today = new Date(),
        trialendDate = parseRubyDate(trialend);

      numDaysLeft = Date.calendarDayDiff(today, trialendDate);

      _i.containerTrial = document.createElement('DIV');
      _i.containerTrial.style.width = 'auto';
      _i.containerTrial.style.height = '100%';
      _i.containerTrial.className = 'fnt-r-14 menuButtonItem blockFloat';
      if (gData.accountSettings.enterprise_header_shadow_enabled === false) {
        _i.containerTrial.style.textShadow = 'none';
      }

      var label = document.createElement('DIV');
      label.className = 'menuButtonItemLabel trialButtonItemLabel';
      if (gData.accountSettings.enterprise_header_color) {
        label.style.color = gData.accountSettings.enterprise_header_color;
      }

      if (numDaysLeft > 0)
        label.innerText =
          `${I18n.t('lbl_trial_colon')} ` +
          numDaysLeft +
          ' ' +
          (numDaysLeft > 1 ? I18n.t('lbl_days_lc') : I18n.t('lbl_day_lc')) +
          ` ${I18n.t('lbl_left_lc')}`;
      else if (numDaysLeft === 0)
        label.innerText = I18n.t('lbl_trial_expires_today');
      else label.innerText = I18n.t('lbl_trial_expired');

      _i.containerTrial.appendChild(label);

      _i.rightItems.appendChild(_i.containerTrial);

      xAddEventListener(
        _i.containerTrial,
        platform.click,
        _i.gotoBilling,
        false
      );
    }

    for (i = RIGHT_ALIGN_FROM_INDEX; i < _i.menuItems.length; i++) {
      item = _i.menuItems[i];
      _i.buttons.push(
        new menuItem(
          i,
          item.elementId,
          item.text,
          _i.rightItems,
          item.dropDown,
          item.action
        )
      );
    }

    new SearchBoxHeader(_i.rightItems, 180, 46, 416, new StringMatcher());

    xAddEventListener(window, 'resize', _i.windowResize, false);
    _i.windowResize();

    _i.setSelection(_i.index);
  };

  _i.constructor();
}

function updatePageHeader(attachTo, selectedindex, username, companyname) {
  pageHeader.attachTo = attachTo || pageHeader.attachTo;
  if (selectedindex || selectedindex === 0) {
    pageHeader.selectedindex = selectedindex;
  }
  pageHeader.username = username || pageHeader.username;
  pageHeader.companyname =
    window.accountSettings.company_name || pageHeader.companyname;

  if (window.gPageHeader) {
    gPageHeader.destroy();
    gPageHeader = null;
  }

  if (pageHeader.attachTo) {
    gPageHeader = new pageHeader(
      pageHeader.attachTo,
      pageHeader.selectedindex,
      pageHeader.username,
      pageHeader.companyname
    );
  }
}
