function personThumbnail(user, attachTo, ignorelink, viewName) {
  var _i = this;

  _i.attachTo = xGetElementById(attachTo);
  if (ignorelink) _i.link = false;
  else _i.link = true;
  _i.cont;

  _i.user = typeof user == 'number' ? gData.getUser(user) : user;

  _i.onClick = function (evt) {
    trackGAEvent(
      typeof getPageTitleFromPath === 'function' &&
        getPageTitleFromPath(window.location),
      'click',
      'Person Thumbnail'
    );
    window.clientEventLogger?.push({
      eventTimestamp: Date.now(),
      eventData: {
        eventName: 'RecordClick',
        featureArea: 'RMAnalytics',
        elementLocation: 'LeftPanel',
        elementName: 'ProfileImage',
      },
    });
    window.location = getBaseURL() + 'users/' + _i.user.id;
  };

  _i.constructor = function () {
    _i.cont = document.createElement('DIV');
    _i.cont.className = 'blockFloat personThumbnailContainer';

    if (
      _i.user.thumbnail === 'https://assets.rm.smartsheet.com/images/anon.jpg'
    ) {
      var overlay = document.createElement('DIV');
      overlay.className = 'personThumbnailOverlay fnt-r-11';
      overlay.innerText = _i.user.displayName;
      _i.cont.appendChild(overlay);
      if (!_i.link) overlay.style.cursor = 'default';
    } else {
      var image = document.createElement('IMG');
      image.className = 'personThumbnailImage';
      image.src = _i.user.thumbnail;
      image.title = _i.user.displayName;
      image.removeAttribute('width');
      image.removeAttribute('height');
      _i.cont.appendChild(image);
      if (!_i.link) image.style.cursor = 'default';
    }

    _i.attachTo.appendChild(_i.cont);
    if (_i.link) xAddEventListener(_i.cont, platform.click, _i.onClick, false);
  };

  _i.constructor();
}
