function SettingsMenuItem(parentObj, attachTo, val, label) {
  var _i = this;
  // params
  _i.parentObj = parentObj;
  _i.attachTo = attachTo;
  _i.label = label;
  _i.val = val;

  _i.onClick = function () {
    _i.parentObj.menuClickCallback(_i, _i.val);
    focusFirstChildElementAfterDelay('#pageMainInfoContentContainer');
  };

  _i.onKeypress = function (e) {
    if (typeof e === 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key === 'Enter' || e.key === ' ') {
      _i.onClick();
    }
    return true;
  };

  _i.select = function () {
    _i.container.className = 'fnt-b-14 settingsMenuItemSelected';
  };

  _i.unselect = function () {
    _i.container.className = 'fnt-r-14 settingsMenuItem';
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.setAttribute('role', 'button');
    _i.container.tabIndex = '0';
    _i.container.className = 'fnt-r-14 settingsMenuItem';
    _i.container.innerText = label;

    if (label) {
      _i.container.id = _i.label.replace(/\s+/g, '-').toLowerCase();
    }

    _i.attachTo.appendChild(_i.container);
    xAddEventListener(_i.container, platform.click, _i.onClick, false);
    xAddEventListener(_i.container, 'keypress', _i.onKeypress, false);
  };
  _i.constructor();
}

function SettingsMenu(parentObj, attachTo) {
  var _i = this;

  // params
  _i.parentObj = parentObj;
  _i.attachTo = xGetElementById(attachTo);

  _i.menuClickCallback = function (item, value) {
    if (item && _i.parentObj) {
      oldval = _i.selected.val;
      _i.selected.unselect();
      item.select();
      _i.selected = item;
      _i.parentObj.menuItemCallback(value, oldval);
    }
  };

  _i.sendSupport = function () {
    var formurl = 'https://10000ft.typeform.com/to/eNtFXX';
    window.location.href = formurl;
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot settingsMenuContainer';
    _i.container.id = 'settingsMenuContainer';
    _i.attachTo.appendChild(_i.container);

    _i.menuitem1 = new SettingsMenuItem(
      _i,
      _i.container,
      1,
      _i.parentObj.labelForMenuItem(1)
    );
    _i.menuitem1.select();
    _i.selected = _i.menuitem1;

    // NOTE - When adding new menu items here, make sure to add them to the lookup array in
    // NOTE - the SettingsPage.pageDataLoaded() function (in settings.js)
    //
    if (
      gData.accountSettings.canUserEditModel(gData.getMe(), 'AccountSetting')
    ) {
      _i.menuitem2 = new SettingsMenuItem(
        _i,
        _i.container,
        2,
        _i.parentObj.labelForMenuItem(2)
      );
      _i.menuitem13 = new SettingsMenuItem(
        _i,
        _i.container,
        13,
        _i.parentObj.labelForMenuItem(13)
      );
      _i.menuitem5 = new SettingsMenuItem(
        _i,
        _i.container,
        5,
        _i.parentObj.labelForMenuItem(5)
      );
      _i.menuitem6 = new SettingsMenuItem(
        _i,
        _i.container,
        6,
        _i.parentObj.labelForMenuItem(6)
      );
      _i.menuitem4 = new SettingsMenuItem(
        _i,
        _i.container,
        4,
        _i.parentObj.labelForMenuItem(4)
      );
      _i.menuitem3 = new SettingsMenuItem(
        _i,
        _i.container,
        3,
        _i.parentObj.labelForMenuItem(3)
      );
      _i.menuitem24 = new SettingsMenuItem(
        _i,
        _i.container,
        24,
        _i.parentObj.labelForMenuItem(24)
      );
      _i.menuitem14 = new SettingsMenuItem(
        _i,
        _i.container,
        14,
        _i.parentObj.labelForMenuItem(14)
      );
      _i.menuitem16 = new SettingsMenuItem(
        _i,
        _i.container,
        16,
        _i.parentObj.labelForMenuItem(16)
      );
      _i.menuitem25 = new SettingsMenuItem(
        _i,
        _i.container,
        25,
        _i.parentObj.labelForMenuItem(25)
      );
      _i.menuitem17 = new SettingsMenuItem(
        _i,
        _i.container,
        17,
        _i.parentObj.labelForMenuItem(17)
      );
      _i.menuitem12 = new SettingsMenuItem(
        _i,
        _i.container,
        12,
        _i.parentObj.labelForMenuItem(12)
      );
      _i.menuitem7 = new SettingsMenuItem(
        _i,
        _i.container,
        7,
        _i.parentObj.labelForMenuItem(7)
      );
      _i.menuitem15 = new SettingsMenuItem(
        _i,
        _i.container,
        15,
        _i.parentObj.labelForMenuItem(15)
      );
      _i.menuitem8 = new SettingsMenuItem(
        _i,
        _i.container,
        8,
        _i.parentObj.labelForMenuItem(8)
      );
      _i.menuitem9 = new SettingsMenuItem(
        _i,
        _i.container,
        9,
        _i.parentObj.labelForMenuItem(9)
      );
      _i.menuitem20 = new SettingsMenuItem(
        _i,
        _i.container,
        20,
        _i.parentObj.labelForMenuItem(20)
      );
      _i.menuitem21 = new SettingsMenuItem(
        _i,
        _i.container,
        21,
        _i.parentObj.labelForMenuItem(21)
      );
      _i.menuitem19 = new SettingsMenuItem(
        _i,
        _i.container,
        19,
        _i.parentObj.labelForMenuItem(19)
      );
      _i.menuitem22 = new SettingsMenuItem(
        _i,
        _i.container,
        22,
        _i.parentObj.labelForMenuItem(22)
      );
      _i.menuitem23 = new SettingsMenuItem(
        _i,
        _i.container,
        23,
        _i.parentObj.labelForMenuItem(23)
      );
      gService.getSmartsheetOrg().then(function (smartsheetOrg) {
        if (
          smartsheetOrg.smartsheet_account_id ||
          gData.accountSettings.sso_type !== ''
        ) {
          _i.menuitem28 = new SettingsMenuItem(
            _i,
            _i.container,
            28,
            _i.parentObj.labelForMenuItem(28)
          );
        }
      });
      if (gData.accountSettings.moduleEnabled('custom branding')) {
        _i.menuitem27 = new SettingsMenuItem(
          _i,
          _i.container,
          27,
          _i.parentObj.labelForMenuItem(27)
        );
      }
    }
    if (QueryString['page'] == 'faq') {
      _i.menuitem11 = new SettingsMenuItem(
        _i,
        _i.container,
        11,
        _i.parentObj.labelForMenuItem(11)
      );
    }
  };

  _i.constructor();
}
